export const FooterResources = {
  address: "447 Broadway, 2nd Floor \r\n New York, NY 10013",
  phone: "800-277-0343",
  socials: [
    {
      icon: "@linkedin",
      url: "https://www.linkedin.com/company/broadwaymedia",
    },
    {
      icon: "@youtube",
      url: "https://www.youtube.com/channel/UCtxeMy2zYcwBiszlOYqDedg",
    },
    {
      icon: "@instagram",
      url: "https://www.instagram.com/broadwaymedia",
    },
    {
      icon: "@facebook",
      url: "https://www.facebook.com/broadwaymedia",
    },
  ],
  solutions: [
    {
      label: "Choral and Music",
      url: "/solutions/choral-and-music",
    },
    {
      label: "Community Theatre",
      url: "/solutions/community-theatre",
    },
    {
      label: "School Theatre",
      url: "/solutions/school-theatre",
    },
    {
      label: "College Theatre",
      url: "/solutions/college-theatre",
    },
    {
      label: "Dance and Ballet",
      url: "/solutions/dance",
    },
    {
      label: "Professional Theatre",
      url: "/solutions/professional-theatre",
    },
    {
      label: "Junior Theatre",
      url: "/solutions/junior-theatre",
    },
  ],
  navigation: [
    [
      {
        label: "Find Your Show",
        url: "/search",
      },
      {
        label: "Contact",
        url: "/contact",
      },
      {
        label: "Partners",
        url: "/partners",
      },
      {
        label: "Company",
        url: "/company",
      },
    ],
    [
      {
        label: "Stories",
        url: "/blog",
      },
      {
        label: "Direct from Broadway",
        url: "/clients",
      },
      {
        label: "Community",
        url: "/community",
      },
      {
        label: "Careers",
        url: "/careers",
      },
    ],
  ],
  subs: [
    {
      label: "Sitemap",
      url: "/sitemap",
    },
    {
      label: "Terms & Conditions",
      url: "/legal/terms-and-conditions",
    },
    {
      label: "Privacy",
      url: "/legal/privacy-policy",
    },
    {
      label: "Code of Conduct",
      url: "/legal/code-of-conduct",
    },
    {
      label: "Cookies",
      url: "/legal/cookie-policy",
    },
    {
      label: "Copyright",
      url: "/legal/copyright",
    },
    {
      label: "Cancellation Policy",
      url: "/legal/cancellation-policy",
    },
    {
      label: "EULA",
      url: "/legal/eula",
    },
    {
      label: "Hardware Rental Agreement",
      url: "/legal/hardware-rental-agreement",
    },
    {
      label: "Payment Agreement",
      url: "/legal/payment",
    },
    {
      label: "Order Form",
      url: "/legal/documents/order-form",
      region: 1,
    },
    {
      label: "Broadway Media Brochure",
      url: "/legal/documents/catalog",
    },
    {
      label: "StageGear BodyMics",
      url: "/legal/documents/stage-gear-bodymics",
    },
    {
      label: "Submissions",
      url: "/legal/submissions",
    },
    {
      label: "Support Policy",
      url: "/legal/support-policy",
    },
    {
      label: "Subscription Service Agreement",
      url: "/legal/subscription-service-agreement",
    },
    {
      label: "W-9",
      url: "/legal/documents/w-9",
    },
    {
      label: "Projectors",
      url: "/legal/documents/projectors",
    },
  ],
};
